import { isBlank, isNotPresent, isPresent } from '../util';
import { getStage } from './env.interfaces';
import { Stage } from '@checkout/shared/src/env';

export const parseString = (name: string): string => {
  const value: string | undefined = process.env[name];
  if (isNotPresent(value) || isBlank(value)) {
    throw new Error(`Env variable '${name}' is not set`);
  }
  return value.trim();
};

export const parseEnvStringOrDefault = (name: string, defaultValue: string): string => {
  const value: string | undefined = process.env[name];
  return value ? value.trim() : defaultValue;
};

export const parseEnvIntegerOrDefault = (name: string, defaultValue: number): number => {
  const value: string | undefined = process.env[name];
  const parsed = parseInt(value ?? '', 10);
  return !isNaN(parsed) ? parsed : defaultValue;
};

export const parseStringArray = (name: string): string[] => {
  return parseString(name).split(',');
};

export const parseEnvStringArrayOrDefault = (name: string): string[] => {
  const string = parseEnvStringOrDefault(name, '');
  return string ? string.split(',').map((value) => value.trim()) : [];
};

export const parseInteger = (name: string): number => {
  // SQS triggered functions return 'number' for integers. Bug in serverless?
  if (isPresent(process.env[name]) && typeof process.env[name] === 'number') {
    return Number(process.env[name]);
  }
  return parseInt(parseString(name), 10);
};

export const parseStage = (name: string): Stage => {
  const value = parseString(name);
  return getStage(value);
};

export interface EnvVariable<T> {
  name: string;
  parse: () => T;
}

export const initVariable = <T>(name: string, parser: (name: string) => T): EnvVariable<T> => ({
  name,
  parse: () => parser(name),
});

export const initOptionalVariable = <T>(
  name: string,
  parser: (name: string, defaultValue: T) => T,
  defaultValue: T
): EnvVariable<T> => ({
  name,
  parse: () => parser(name, defaultValue),
});

export const ENV_STAGE: EnvVariable<Stage> = initVariable('STAGE', parseStage);
export const ENV_STATIC_CACHE_TTL: EnvVariable<number> = initVariable('STATIC_CACHE_TTL', parseInteger);
export const ENV_CHECKOUT_TABLE_NAME: EnvVariable<string> = initVariable('CHECKOUT_TABLE_NAME', parseString);
export const ENV_CORS_ALLOWED_ORIGINS: EnvVariable<string[]> = initVariable('CORS_ALLOWED_ORIGINS', parseStringArray);
export const ENV_PAYMENT_API_URL: EnvVariable<string> = initVariable('PAYMENT_API_URL', parseString);
export const ENV_PAYMENT_API_TIMEOUT_MS: EnvVariable<number> = initVariable('PAYMENT_API_TIMEOUT_MS', parseInteger);
export const ENV_PGW_THREEDS_API_URL: EnvVariable<string> = initVariable('PGW_THREEDS_API_URL', parseString);
export const ENV_PGW_THREEDS_API_TIMEOUT_MS: EnvVariable<number> = initVariable(
  'PGW_THREEDS_API_TIMEOUT_MS',
  parseInteger
);
export const ENV_NOTIFICATION_URL: EnvVariable<string> = initVariable('NOTIFICATION_URL', parseString);
export const ENV_CHECKOUT_API_URL: EnvVariable<string> = initVariable('CHECKOUT_API_URL', parseString);
export const ENV_FRONTEND_URL: EnvVariable<string> = initVariable('FRONTEND_URL', parseString);
export const ENV_SECRETS_NAME: EnvVariable<string> = initVariable('SECRETS_NAME', parseString);
export const ENV_CAS_URL: EnvVariable<string> = initVariable('CAS_URL', parseString);
export const ENV_CAS_CLIENT_ID: EnvVariable<string> = initVariable('CAS_CLIENT_ID', parseString);
export const ENV_DDS_URL: EnvVariable<string> = initVariable('DDS_URL', parseString);
export const ENV_SHOP_API_TIMEOUT_MS: EnvVariable<number> = initVariable('SHOP_API_TIMEOUT_MS', parseInteger);
export const ENV_TRACE_LOG_GROUP_NAME: EnvVariable<string> = initVariable('TRACE_LOG_GROUP_NAME', parseString);
export const ENV_ALARM_WARNING_SNS_TOPIC: EnvVariable<string> = initVariable('ALARM_WARNING_SNS_TOPIC', parseString);
export const ENV_ALARM_ERROR_SNS_TOPIC: EnvVariable<string> = initVariable('ALARM_ERROR_SNS_TOPIC', parseString);
export const ENV_NOTIFICATIONS_QUEUE_URL: EnvVariable<string> = initVariable('NOTIFICATIONS_QUEUE_URL', parseString);
export const ENV_CONFIRM_QUEUE_URL: EnvVariable<string> = initVariable('CONFIRM_QUEUE_URL', parseString);
export const ENV_SQS_MAX_RETRY_COUNT: EnvVariable<number> = initVariable('SQS_MAX_RETRY_COUNT', parseInteger);
export const ENV_GIFT_CARD_API_URL: EnvVariable<string> = initVariable('GIFT_CARD_API_URL', parseString);
export const ENV_LOYALTY_MEMBERS_API_URL: EnvVariable<string> = initVariable('LOYALTY_MEMBERS_API_URL', parseString);
export const ENV_LOYALTY_TRANSACTIONS_API_URL: EnvVariable<string> = initVariable(
  'LOYALTY_TRANSACTIONS_API_URL',
  parseString
);
export const ENV_SERVICES_WITH_GIFT_CARDS_ENABLED: EnvVariable<string[]> = initVariable(
  'SERVICES_WITH_GIFT_CARDS_ENABLED',
  parseStringArray
);
export const EXPIRE_STATE_MACHINE_ARN: EnvVariable<string> = initVariable('EXPIRE_STATE_MACHINE_ARN', parseString);
export const ENV_ENCRYPTION_KEY_ARN: EnvVariable<string> = initVariable('ENCRYPTION_KEY_ARN', parseString);
export const ENV_EVENT_STREAM_NAME: EnvVariable<string> = initVariable('EVENT_STREAM_NAME', parseString);
export const ENV_ATHENA_DATABASE_NAME: EnvVariable<string> = initVariable('ATHENA_DATABASE_NAME', parseString);
export const ENV_ATHENA_WORKGROUP: EnvVariable<string> = initVariable('ATHENA_WORKGROUP', parseString);
export const ENV_APP_CONFIG_APPLICATION_ID: EnvVariable<string> = initVariable(
  'APP_CONFIG_APPLICATION_ID',
  parseString
);
export const ENV_APP_CONFIG_ENVIRONMENT_ID: EnvVariable<string> = initVariable(
  'APP_CONFIG_ENVIRONMENT_ID',
  parseString
);
export const PENDING_AS_SUCCESS_DEPARTURE_THRESHOLD_HOURS: EnvVariable<number> = initVariable(
  'PENDING_AS_SUCCESS_DEPARTURE_THRESHOLD_HOURS',
  parseInteger
);
export const PENDING_AS_SUCCESS_EMAIL_DELAY_MINUTES: EnvVariable<number> = initVariable(
  'PENDING_AS_SUCCESS_EMAIL_DELAY_MINUTES',
  parseInteger
);

export const PUBLISH_SNS_LAMBDA_ARN: EnvVariable<string> = initVariable('PUBLISH_SNS_LAMBDA_ARN', parseString);
export const EVENT_BRIDGE_ROLE_ARN: EnvVariable<string> = initVariable('EVENT_BRIDGE_ROLE_ARN', parseString);
export const DATALAKE_STREAM_NAME: EnvVariable<string> = initVariable('DATALAKE_STREAM_NAME', parseString);
export const CHECKOUT_TABLE_CHANGE_TOPIC_ARN: EnvVariable<string> = initVariable(
  'CHECKOUT_TABLE_CHANGE_TOPIC_ARN',
  parseString
);
